<template>
  <div
    class="
      w-full
      md:min-h-screen
      h-full
      register-container
      grid
      place-items-center
    "
  >
    <div class="w-10/12 container-register pb-4 mx-auto">
      <transition name="slideUp" mode="out-in">
        <div class="register-form" key="form">
          <h2
            class="
              text-lg text-center
              lg:text-left
              md:text-2xl
              font-semibold
              mt-10
              lg:mt-0
            "
          >
            <span class="text-grey">Just to be sure.</span> We sent an OTP to
            your email
          </h2>
          <p class="text-center lg:text-left text-grey mt-2">
            We sent a secret code to your email ({{ ActiveUser.email }}). Kindly
            provide it below
          </p>
          <div class="relative">
            <Input
              type="number"
              width="w-full"
              placeholder="Enter OTP"
              class="mt-14"
              v-model="otp"
              hideErrorIcon
              errorText="OTP must be 6 characters"
              :validation="rules.otp"
              @valid="otpValid = $event"
            />
            <transition name="fade" mode="out-in">
              <Button
                v-if="showResendButton"
                text="Resend OTP"
                class="resend-otp z-10"
                background="bg-lightRed"
                color="text-brandRed"
                p="px-2.5 py-2"
                fontSize="text-xs"
                radius="rounded-3xl"
                width="w-28"
                :loading="verifyLoading"
                @click="resendOtp"
              />
            </transition>
          </div>

          <div class="mt-12">
            <div class="spam-notice mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                  fill="#0EA2F5"
                />
              </svg>
              <p class="text-sm">
                If you don’t see our email, please check your spam folder
              </p>
            </div>
            <Button
              text="Verify email"
              class="mt-2"
              @click="submitPassword"
              :shadow="true"
              width="w-full"
              :loading="loading"
              :disabled="disableButton"
            />
          </div>
          <div class="mt-5">
            <p class="text-sm text-center text-grey font-semibold">
              Already have an Account?
              <router-link to="/login">
                <span class="text-black underline">Sign In</span>
              </router-link>
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Input from "../../../UI/Input";
import Button from "@/UI/Button";
export default {
  components: {
    Input,
    Button,
  },
  data() {
    return {
      otp: "",
      loading: false,
      showResendButton: false,
      verifyLoading: false,
      otpValid: false,
    };
  },
  computed: {
    ActiveUser() {
      return this.$store.getters["auth/ActiveUser"];
    },
    registrationId() {
      return this.$store.getters["auth/registrationId"];
    },
    rules() {
      return {
        otp: this.otp.length === 6,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    this.handleDelayOtpButton();
  },
  methods: {
    ...mapActions("auth", ["verifyOtp", "register"]),
    ...mapActions("notification", ["showAlert"]),
    submitPassword() {
      if (this.otpValid === false) {
        this.showAlert({
          display: true,
          description: "Please enter a valid OTP",
          type: "error",
          title: "Validation Error",
        });
      } else {
        this.loading = true;

        this.verifyOtp({
          registrationId: this.registrationId,
          firstName: this.ActiveUser.firstName,
          lastName: this.ActiveUser.lastName,
          password: this.ActiveUser.password,
          otp: this.otp,
        })
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    resendOtp() {
      this.verifyLoading = true;

      this.register({
        email: this.ActiveUser.email,
        secret: this.ActiveUser.password,
      })
        .then(() => {
          this.verifyLoading = false;
          this.showAlert({
            display: true,
            type: "success",
            description: `A new otp has been sent to your email address`,
          });
          this.showResendButton = false;
          this.handleDelayOtpButton();
        })
        .catch(() => {
          this.verifyLoading = false;
        });
    },
    handleDelayOtpButton() {
      setTimeout(() => {
        this.showResendButton = true;
      }, 60000);
    },
  },
};
</script>
<style scoped>
@import "../../../assets/styles/animation.css";
/* .register-container {
    min-height: 100vh;
  } */
.register-container h3 {
  font-size: 35px;
}
.register-container p {
  line-height: 30.15px;
}
.container-register {
  max-width: 33.831rem;
}
.resend-otp {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
