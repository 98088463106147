<template>
  <Otp />
</template>
<script>
import Otp from "@/components/Auth/Otp";
export default {
  components: {
    Otp,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/register") {
        next("/otp");
      } else {
        next("/register");
      }
      next();
    });
  },
};
</script>
